import {
  createClient,
  createImageUrlBuilder,
  createPreviewSubscriptionHook
} from 'next-sanity'
import {
  getHomeDataQuery,
  getAllPostsQuery,
  getPostPageDataQuery,
  getAboutDataQuery,
  getAllGalleryItemsQuery,
  getAllSponsorsQuery,
  getOfferPageQuery,
  getGalleryItemsQuery,
  getContactPageDataQuery,
  getEventsQuery,
  getSiteSettingsQuery,
  getAllEventsQuery,
  getAllCategoriesQuery,
  getEventPageDataQuery,
  getRegularPageDataQuery
} from './queries'

const config = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: process.env.NODE_ENV === 'production',
  token: process.env.NEXT_PUBLIC_SANITY_API_TOKEN,
  apiVersion: '2021-03-25'
}

export const imageBuilder = (source) =>
  createImageUrlBuilder(config).image(source)
export const usePreviewSubscription = createPreviewSubscriptionHook(config)
export const client = createClient(config)
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.NEXT_PUBLIC_SANITY_API_TOKEN
})

export const getClient = (usePreview) => (usePreview ? previewClient : client)
export default client

export async function getHomeData(preview) {
  const data = await getClient(preview).fetch(getHomeDataQuery)

  return data
}

export async function getAboutData(slug, preview) {
  const data = await getClient(preview).fetch(getAboutDataQuery, {
    slug
  })

  return data
}

export async function getEventsPageData(preview) {
  const data = await getClient(preview).fetch(getEventsQuery)
  return data
}

export async function getContactPageData(preview) {
  const data = await getClient(preview).fetch(getContactPageDataQuery)

  return data
}

export async function getRecentPosts(preview) {
  const data = await getClient(preview).fetch(getAllPostsQuery)
  return data
}

export async function getAllPosts(preview) {
  const data = await getClient(preview).fetch(getAllPostsQuery)
  return data
}

export async function getAllEvents(preview) {
  const data = await getClient(preview).fetch(getAllEventsQuery)
  return data
}

export async function getAllCategories(preview) {
  const data = await getClient(preview).fetch(getAllCategoriesQuery)
  return data
}

export async function getAllGalleryPosts(preview) {
  const data = await getClient(preview).fetch(getAllGalleryItemsQuery)
  return data
}

export async function getGalleryPosts(count, preview) {
  const data = await getClient(preview).fetch(getGalleryItemsQuery(count))
  return data
}

export async function getPostPageData(slug, preview) {
  const data = await getClient(preview).fetch(getPostPageDataQuery, {
    slug: slug
  })
  return data
}

export async function getEventPageData(slug, preview) {
  const data = await getClient(preview).fetch(getEventPageDataQuery, {
    slug: slug
  })
  return data
}

export async function getRegularPageData(id, preview) {
  const data = await getClient(preview).fetch(getRegularPageDataQuery, {
    id: id
  })
  return data
}

export async function getOfferPageData(id, preview) {
  const data = await getClient(preview).fetch(getOfferPageQuery(id))
  return data
}

export async function getAllSponsors(preview) {
  const data = await getClient(preview).fetch(getAllSponsorsQuery)
  return data
}

export async function getSiteSettings(preview) {
  const data = await getClient(preview).fetch(getSiteSettingsQuery)
  return data
}
