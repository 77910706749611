import { groq } from 'next-sanity'

export const postFragment = groq`
  _id,
  title,
  isFeatured,
  slug,
  excerpt,
  "category": category[]->,
  publishedAt,
  mainImage,
  headerImage,
  body,
  openGraph
`

export const eventFragment = groq`
  _id,
  title,
  isFeatured,
  slug,
  dateFrom,
  dateTo,
  timeSlot,
  excerpt,
  "category": category[]->,
  publishedAt,
  mainImage,
  headerImage,
  city,
  body,
  faqs,
  openGraph
`

export const galleryItemFragment = groq`
  _id,
  title,
  image
`

export const categoryFragment = groq`
  _id,
  title,
  description,
  "count": count(*[_type == "event" && references(^._id) && !(_id in path("drafts.**"))])
`

export const offerPageFragment = groq`
  _id,
  title,
  body,
  openGraph
`

export const regularPageFragment = groq`
  _id,
  title,
  body,
  openGraph
`

export const sponsorFragment = groq`
  _id,
  name,
  url,
  isPartner,
  quoteHeading,
  quoteBody,
  image
  `

export const getHomePageDataQuery = groq`*[_type == "homePage"][0] {
  _id,
  title,
  subtitle,
  hero,
  body,
  openGraph,
  reviews,
  specialOffer,
  specialOffer2
}`

export const getSiteSettingsQuery = groq`*[_type == "siteSettings"][0] {
  _id,
  openGraph
}`

export const getAboutDataQuery = groq`*[_type == "aboutPage"][0] {
  _id,
  title,
  body,
  aims,
  team
}`

export const getEventsQuery = groq`*[_type == "eventsPage"][1] {
  _id,
  body
}`

export const getContactPageDataQuery = groq`*[_type == "contactPage"][0] {
  _id,
  title,
  body
}`

export const getRegularPageDataQuery = groq`*[_id  == $id][0] {
  ${regularPageFragment}
}`

export const getAllPostsQuery = groq`*[_type == "post"] {
  ${postFragment}
}`

export const getAllEventsQuery = groq`*[_type == "event"] {
  ${eventFragment}
}`

export const getAllCategoriesQuery = groq`*[_type == "category"] {
  ${categoryFragment}
}`

export const getAllGalleryItemsQuery = groq`*[_type == "gallery"] {
  ${galleryItemFragment}
}`

export const getGalleryItemsQuery = (
  count
) => groq`*[_type == "gallery"][0..${count}] {
  ${galleryItemFragment}
}`

export const getPostPageDataQuery = groq`*[_type == "post" && slug.current == $slug][0]{
  ${postFragment}
}`

export const getEventPageDataQuery = groq`*[_type == "event" && slug.current == $slug][0]{
  ${eventFragment}
}`

export const getAllSponsorsQuery = groq`*[_type == "sponsor"]{
  ${sponsorFragment}
}`

export const getOfferPageQuery = (id) =>
  groq`*[_type == "offerPage" && _id == "${id}"][0]{
  ${offerPageFragment}
}`

export const getHomeDataQuery = groq`{
  "home": ${getHomePageDataQuery},
  "sponsors": ${getAllSponsorsQuery},
  "gallery": ${getGalleryItemsQuery(5)},
  "siteSettings": ${getSiteSettingsQuery},
}`
